import React from 'react'
import Glider from 'react-glider'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons'
import HowItWorks from './HowItWorks'
import { prismicText } from '../Global/CMSText'

type Tprops = {
  homePage?: any
  t?: any
}

const SliderHowItWork = ({ homePage, t }: Tprops) => {
  return (
    <>
      {homePage?.data?.howitworks.length > 2 ? (
        <Glider
          hasArrows
          draggable
          scrollLock
          iconLeft={<KeyboardArrowLeft />}
          iconRight={<KeyboardArrowRight />}
          slidesToShow={1}
          scrollLockDelay={100}
          duration={1}
        >
          {homePage?.data?.howitworks.map((item, index) => {
            return (
              <HowItWorks
                homePage={homePage}
                key={index}
                titleVariant="h4"
                descVariant="body1"
                icon={item.image.url}
                title={prismicText(item?.title1)}
                desc={prismicText(item?.desc)}
              />
            )
          })}
        </Glider>
      ) : (
        <Glider
          draggable
          scrollLock
          slidesToShow={1}
          hasDots
          scrollLockDelay={100}
          duration={1}
        >
          {[0, 1, 2].map((key) => {
            return (
              <HowItWorks
                homePage={homePage}
                key={key}
                titleVariant="h4"
                descVariant="body1"
                icon={'/images/placeholder.jpg'}
                title={t('texts:general:add_a_title')}
                desc={t('texts:general:add_a_description')}
              />
            )
          })}
        </Glider>
      )}
    </>
  )
}

export default SliderHowItWork
