import React from 'react'
import clsx from 'clsx'
import {
  Typography,
  makeStyles,
  createStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'

type Tprops = {
  icon?: string
  title?: string
  desc: any
  titleVariant?: any
  descVariant?: any
  homePage: any
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'left',
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(2, 0),
      },
    },
    rootNoIcon: {
      [theme.breakpoints.down('sm')]: {
        display: 'block',
      },
    },
    title: {
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(1.5, 0),
        minHeight: '50px',
        verticalAlign: 'middle',
      },
    },
    icon: {
      display: 'block',
      margin: '0 auto',
      width: 200,
      maxHeight: 200,
      [theme.breakpoints.down('sm')]: {
        width: 100,
      },
    },
    iconWrapper: {
      [theme.breakpoints.down('sm')]: {
        width: 100,
      },
    },
    content: {},
    addPadding: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    desc: {
      [theme.breakpoints.down('xs')]: {
        fontSize: `calc(${theme.typography.body1.fontSize}px * 0.95)`,
        lineHeight: `calc(${theme.typography.body1.lineHeight} * 0.8)`,
      },
    },
    isSlideOnMobileContainer: {
      flexDirection: 'column',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    isSlideOnMobileIcon: {},
    isSlideOnMobileContent: {
      textAlign: 'center',
    },
    isSlideOnMobileDesc: {},
  })
)

const HowItWorks = (props: Tprops) => {
  const classes = useStyles()
  const theme = useTheme()
  const { icon, title, desc, titleVariant, descVariant, homePage } = props
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isSlideOnMobile = homePage?.data?.display_slider_hiw_section_on_mobile

  return (
    <div
      className={clsx(
        'hiw',
        classes.root,
        !isMobile && classes.addPadding,
        !icon && classes.rootNoIcon,
        isMobile && isSlideOnMobile && classes.isSlideOnMobileContainer
      )}
    >
      {icon && (
        <div className={clsx('hiw__icon', classes.iconWrapper)}>
          <img
            src={icon}
            alt=""
            className={clsx(
              'hiw__img',
              classes.icon,
              isMobile && isSlideOnMobile && classes.isSlideOnMobileIcon
            )}
          />
        </div>
      )}
      <div
        className={clsx(
          'hiw__content',
          classes.content,
          isMobile && classes.addPadding,
          isMobile && isSlideOnMobile && classes.isSlideOnMobileContent
        )}
      >
        <div className={clsx('hiw__head', classes.title)}>
          {title && (
            <Typography
              variant={titleVariant || 'h3'}
              component={'p'}
              color={'primary'}
              className={'hiw__title'}
            >
              {title}
            </Typography>
          )}
        </div>
        {desc && (
          <Typography
            variant={descVariant || 'body1'}
            className={clsx(
              'hiw__desc',
              classes.desc,
              isMobile && isSlideOnMobile && classes.isSlideOnMobileDesc
            )}
            component={'div'}
          >
            {desc}
          </Typography>
        )}
      </div>
    </div>
  )
}

export default HowItWorks
