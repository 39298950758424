import {
  Typography,
  Grid,
  makeStyles,
  createStyles,
  Container,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import React from 'react'
import HowItWorks from './HowItWorks'
import Link from '../Link/Link'
import Button from '../Button/Button'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import CMSText, { isPrismicText, prismicText } from '../Global/CMSText'
import SliderHowItWork from './SliderHowItWork'

type Tprops = {
  homePage?: any
  t?: any
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: theme.spacing(6, 0),
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(2, 0),
      },
    },
    align_center: {
      textAlign: 'center',
      margin: 'auto',
    },
    padding_top: {
      paddingTop: theme.spacing(4),
    },
    spacing: {
      padding: theme.spacing(8, 0),
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(0),
      },
    },
    slideSpacing: {
      padding: theme.spacing(0, 0, 6, 0),
    },
    faqItem: {
      marginBottom: theme.spacing(2),
    },
  })
)

const HowItWorksSection = ({ homePage, t }: Tprops) => {
  const classes = useStyles()
  const theme = useTheme()

  const { trackEvent } = useMatomo()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isSlideOnMobile = homePage?.data?.display_slider_hiw_section_on_mobile

  return (
    <>
      <section className={`section-hiw section__spacer ${classes.root}`}>
        <Container>
          <div className={`${classes.align_center}`}>
            <Typography
              color={'primary'}
              variant={'h2'}
              className={`section-hiw__title ${classes.padding_top}`}
            >
              <CMSText
                data={homePage?.data?.titlehowitworks}
                defaultText={t('texts:homepage:titlehowitworks')}
              />
            </Typography>
            {homePage?.data?.deschowitworks1[0]?.text.length > 0 && (
              <Typography
                variant={'body1'}
                className={`section-hiw__subtitle ${classes.padding_top}`}
              >
                {prismicText(homePage?.data?.deschowitworks1)}
              </Typography>
            )}
            {homePage?.data?.deschowitworks2[0]?.text.length > 0 && (
              <Typography
                variant={'body2'}
                className={`section-hiw__subtitle ${classes.padding_top}`}
              >
                {prismicText(homePage?.data?.deschowitworks2)}
              </Typography>
            )}
            {homePage?.data?.howitworks.length > 0 &&
            isSlideOnMobile &&
            isMobile ? (
              <div className={classes.slideSpacing}>
                <SliderHowItWork homePage={homePage} t={t} />
              </div>
            ) : (
              homePage?.data?.howitworks.length > 0 && (
                <Grid
                  container
                  className={`section-hiw__spacing ${classes.spacing}`}
                >
                  {homePage?.data?.howitworks.length > 2
                    ? homePage?.data?.howitworks.map((item, index) => {
                        return (
                          <Grid item xs={12} md={4} key={index}>
                            <HowItWorks
                              homePage={homePage}
                              titleVariant="h4"
                              descVariant="body1"
                              icon={item.image.url}
                              title={prismicText(item?.title1)}
                              desc={<CMSText data={item.desc} />}
                            />
                          </Grid>
                        )
                      })
                    : [0, 1, 2].map((key) => {
                        return (
                          <Grid item sm={12} lg={4} xs={12} key={key}>
                            <HowItWorks
                              homePage={homePage}
                              titleVariant="h4"
                              descVariant="body1"
                              icon={'/images/placeholder.jpg'}
                              title={t('texts:general:add_a_title')}
                              desc={t('texts:general:add_a_description')}
                            />
                          </Grid>
                        )
                      })}
                </Grid>
              )
            )}

            {isPrismicText(homePage?.data?.cta_how_it_works) && (
              <Link href={homePage?.data?.cta_hiw_link?.url || '/shop'}>
                <a>
                  <Button
                    variant={'contained'}
                    color={'primary'}
                    className={'section-hiw__cta'}
                    onClick={() =>
                      trackEvent({
                        category: 'HomePage',
                        href: homePage?.data?.cta_hiw_link?.url || '/shop',
                        action: `CTA click ${prismicText(
                          homePage?.data?.cta_how_it_works
                        )}`,
                        name: `from How it works section to ${
                          homePage?.data?.cta_hiw_link?.url || '/shop'
                        }`,
                      })
                    }
                  >
                    {prismicText(homePage?.data?.cta_how_it_works)}
                  </Button>
                </a>
              </Link>
            )}
          </div>
        </Container>
      </section>
    </>
  )
}

export default HowItWorksSection
